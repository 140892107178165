import { Select } from "../Components/Select";
import { useState } from "preact/hooks";
import { range } from "../utils";
import { tabelaCartaoQtdFuncionariosESocial } from "../values";

// Passos de quantos funcionários ativos será mostrado no select
const listaFuncionarios = Object.keys(tabelaCartaoQtdFuncionariosESocial);

export function Implantacao({ handleChange, values }) {
	const [plano, setPlano] = useState("iniciante");
	const [isIsento, setIsIsento] = useState(false);
	const [hasMigracao, setHasMigracao] = useState(false);

	function handlePlano(e) {
		setPlano(e.target.value);
		setIsIsento(e.target.value === "isento");
		handleChange(e);
	}

	function handleMigracao(e) {
		setHasMigracao(e.target.value === "1");
		handleChange(e);
	}

	return (
		<div>
			<h2>Implantação</h2>

			<Select
				label="Escolha seu plano"
				onChange={handlePlano}
				name="plano_implantacao"
				value={values.plano_implantacao}
				required={true}
			>
				<option value="" disabled={true} selected={true}>
					- Selecione uma opção -
				</option>
				<option value="isento">Isento</option>
				<option value="iniciante">Iniciante</option>
				<option value="pro">Pró</option>
				<option value="master">Master</option>
			</Select>

			{plano === "isento" && (
				<Select
					label="Capacitação adicional"
					onChange={handleChange}
					name="capacitacao_adicional"
					value={values.capacitacao_adicional}
					required={true}
				>
					<option value="" disabled={true} selected={true}>
						- Selecione uma opção -
					</option>
					{range(0, 10, 1, true).map((num) => (
						<option value={num}>
							{num} hora{num > 1 ? "s" : ""} de capacitação
						</option>
					))}
				</Select>
			)}

			<>
				<h2>Migração de dados:</h2>

				{plano !== "isento" && (
					<Select
						label="Qual a forma de pagamento?"
						onChange={handleChange}
						name="forma_pagamento"
						value={values.forma_pagamento}
						required={true}
					>
						<option value="" disabled={true} selected={true}>
							- Selecione uma opção -
						</option>
						<option value="pix">Pix</option>
						<option value="cartao">Cartão</option>
					</Select>
				)}

				<Select
					label="Irá necessitar de Migração?"
					onChange={handleMigracao}
					name="migracao_dados"
					value={values.migracao_dados}
					required={true}
				>
					<option value="1">Sim</option>
					<option value="0">Não</option>
				</Select>

				{hasMigracao && (
					<>
						<Select
							label="Qual o modelo de migração?"
							name="modelo_migracao"
							onChange={handleChange}
							value={values.modelo_migracao}
							required={true}
						>
							<option value="esocial">Pelo eSocial</option>
							<option value="banco_de_dados">
								Por Banco de Dados
							</option>
						</Select>

						<Select
							label="Quantidade de Funcionários que deseja migrar"
							name="qtd_funcionarios"
							onChange={handleChange}
							value={values.qtd_funcionarios}
							required={true}
						>
							{listaFuncionarios.map((qty) => (
								<option value={qty} key={qty}>
									Até {qty} Funcionários Ativos
								</option>
							))}
						</Select>

						{plano === "isento" && (
							<Select
								label="Qual a forma de pagamento?"
								onChange={handleChange}
								name="forma_pagamento"
								value={values.forma_pagamento}
								required={true}
							>
								<option
									value=""
									disabled={true}
									selected={true}
								>
									- Selecione uma opção -
								</option>
								<option value="pix">Pix</option>
								<option value="cartao">Cartão</option>
							</Select>
						)}
					</>
				)}
			</>
		</div>
	);
}
