/**
 *
 */
const descontoImplantacao = {
	master: 100,
};

/**
 * Tabela de valores referentes ao campo "Escolha Seu plano"
 */
const tabelaValorPlano = {
	isento: 0,
	light: 195,
	basico: 295,
	intermediario: 395,
	avancado: 495,
};

/**
 * Tabela de valores Pix referentes ao valor de implantacao juntado com o metodo de pagamento
 */
const tabelaValorImplantacaoPix = {
	isento: 0,
	light: 0,
	iniciante: 630,
	pro: 1035,
	master: 1215,
};

/**
 * Tabela de valores Cartão referentes ao valor de implantacao juntado com o metodo de pagamento
 */
const tabelaValorImplantacaoCartao = {
	isento: 0,
	light: 0,
	iniciante: 700,
	pro: 1150,
	master: 1350,
};

/**
 * Esta tabela de valores é referente a quantidade de usuários, seleção de cartão
 * e funciona da seguinte maneira:
 * - Se a quantidade de funcionários for 30, o valor é 600
 * - Se a quantidade de funcionários for 60, o valor é 1200
 * Assim por diante...
 */
export const tabelaCartaoQtdFuncionariosESocial = {
	30: 900,
	60: 1500,
	90: 1800,
	120: 2160,
	200: 3200,
	300: 4200,
	400: 4800,
	500: 5000,
};

export const tabelaCartaoQtdFuncionariosBancoDados = {
	30: 1190,
	60: 1850,
	90: 2260,
	120: 2760,
	200: 4200,
	300: 5400,
	400: 6395,
	500: 6999,
};

/**
 * Esta tabela de valores é referente a quantidade de usuários, seleção de Pix
 * e funciona da seguinte maneira:
 * - Se a quantidade de funcionários for 30, o valor é 540
 * - Se a quantidade de funcionários for 60, o valor é 1080
 * Assim por diante...
 */
const tabelaPixQtdFuncionariosESocial = {
	30: tabelaCartaoQtdFuncionariosESocial[30] - (tabelaCartaoQtdFuncionariosESocial[30] * 0.1),
	60: tabelaCartaoQtdFuncionariosESocial[60] - (tabelaCartaoQtdFuncionariosESocial[60] * 0.1),
	90: tabelaCartaoQtdFuncionariosESocial[90] - (tabelaCartaoQtdFuncionariosESocial[90] * 0.1),
	120: tabelaCartaoQtdFuncionariosESocial[120] - (tabelaCartaoQtdFuncionariosESocial[120] * 0.1),
	200: tabelaCartaoQtdFuncionariosESocial[200] - (tabelaCartaoQtdFuncionariosESocial[200] * 0.1),
	300: tabelaCartaoQtdFuncionariosESocial[300] - (tabelaCartaoQtdFuncionariosESocial[300] * 0.1),
	400: tabelaCartaoQtdFuncionariosESocial[400] - (tabelaCartaoQtdFuncionariosESocial[400] * 0.1),
	500: tabelaCartaoQtdFuncionariosESocial[500] - (tabelaCartaoQtdFuncionariosESocial[500] * 0.1),
};

const tabelaPixQtdFuncionariosBancoDados = {
	30: tabelaCartaoQtdFuncionariosBancoDados[30] - (tabelaCartaoQtdFuncionariosBancoDados[30] * 0.1),
	60: tabelaCartaoQtdFuncionariosBancoDados[60] - (tabelaCartaoQtdFuncionariosBancoDados[60] * 0.1),
	90: tabelaCartaoQtdFuncionariosBancoDados[90] - (tabelaCartaoQtdFuncionariosBancoDados[90] * 0.1),
	120: tabelaCartaoQtdFuncionariosBancoDados[120] - (tabelaCartaoQtdFuncionariosBancoDados[120] * 0.1),
	200: tabelaCartaoQtdFuncionariosBancoDados[200] - (tabelaCartaoQtdFuncionariosBancoDados[200] * 0.1),
	300: tabelaCartaoQtdFuncionariosBancoDados[300] - (tabelaCartaoQtdFuncionariosBancoDados[300] * 0.1),
	400: tabelaCartaoQtdFuncionariosBancoDados[400] - (tabelaCartaoQtdFuncionariosBancoDados[400] * 0.1),
	500: tabelaCartaoQtdFuncionariosBancoDados[500] - (tabelaCartaoQtdFuncionariosBancoDados[500] * 0.1),
};

const tabelaQuantidadeEmpresasPlano = {
	light: 6,
	basico: 12,
	intermediario: 25,
	avancado: 'ilimitado',
};

const tabelaQuantidadeFuncionariosPlano = {
	light: 10,
	basico: 50,
	intermediario: 100,
	avancado: 'ilimitado',
};

function calcularDescontoImplantacao({ plano_implantacao }) {
	if (descontoImplantacao[plano_implantacao]) {
		return descontoImplantacao[plano_implantacao];
	}

	return 0;
}

/**
 * Função que calcula o valor da licença
 * @param quantidade_licencas
 * @param plano_escolhido
 */
function calcularValorLicenca({ quantidade_licencas, plano_escolhido }) {
	// Se for qualquer outro plano, e tiver 1 licença, o valor é 0
	if (parseInt(quantidade_licencas) <= 1) return 0;

	if (['light', 'basico', 'intermediario'].includes(plano_escolhido)) {
		return (parseInt(quantidade_licencas) - 1) * 60;
	}

	// Caso avançado: se for maior que 1, o valor é (quantidade_licencas - 1) * 90
	return (parseInt(quantidade_licencas) - 1) * 90;
}

function calcularValorMigracaoPorUsuario({ migracao_dados, modelo_migracao, qtd_funcionarios, forma_pagamento }) {
	if (parseInt(migracao_dados) === 0) return 0;

	if (qtd_funcionarios === 0) return 0;

	if (forma_pagamento === "pix") {
		if (modelo_migracao === "esocial" && tabelaPixQtdFuncionariosESocial[qtd_funcionarios]) {
			return tabelaPixQtdFuncionariosESocial[qtd_funcionarios];
		}

		if (modelo_migracao === "banco_de_dados" && tabelaPixQtdFuncionariosBancoDados[qtd_funcionarios]) {
			return tabelaPixQtdFuncionariosBancoDados[qtd_funcionarios];
		}

		return 0;
	}

	if (forma_pagamento === "cartao") {
		if (modelo_migracao === "esocial" && tabelaCartaoQtdFuncionariosESocial[qtd_funcionarios]) {
			return tabelaCartaoQtdFuncionariosESocial[qtd_funcionarios];
		}

		if (modelo_migracao === "banco_de_dados" && tabelaCartaoQtdFuncionariosBancoDados[qtd_funcionarios]) {
			return tabelaCartaoQtdFuncionariosBancoDados[qtd_funcionarios];
		}

		return 0;
	}

	throw new Error(`Valor não encontrado para ${forma_pagamento} e ${qtd_funcionarios} funcionários.`);
}

function calcularValorImplantacao({ plano_implantacao, capacitacao_adicional, forma_pagamento, migracao_dados }) {
	if (plano_implantacao === 'isento') {
		return calcularCapacitacaoAdicional({ plano_implantacao, capacitacao_adicional });
	};

	if (!migracao_dados) return 0;

	if (forma_pagamento === 'pix' && Number.isFinite(tabelaValorImplantacaoPix[plano_implantacao])) {
		return tabelaValorImplantacaoPix[plano_implantacao];
	}

	if (forma_pagamento === 'cartao' && Number.isFinite(tabelaValorImplantacaoCartao[plano_implantacao])) {
		return tabelaValorImplantacaoCartao[plano_implantacao];
	}

	throw new Error(`Valor não encontrado para ${forma_pagamento} e ${plano_implantacao}`);
}

function calcularValorPlano({ plano_escolhido, quantidade_licencas }) {
	if (!tabelaValorPlano[plano_escolhido]) throw new Error(`Valor não encontrado para ${plano_escolhido}`);

	return tabelaValorPlano[plano_escolhido];
}

function calcularValorCnd({ makro_cnd, makro_dfe, makro_dse }) {
	const valor = makro_dfe === "1" || makro_dse === "1" ? 89 : 99.9;

	return makro_cnd === "1" ? { mensalidadeCnd: valor, ativacaoCnd: 200 } : { mensalidadeCnd: 0, ativacaoCnd: 0 };
}

function calcularValorDfe({ makro_dfe, makro_cnd, makro_dse }) {
	const valor = makro_cnd === "1" || makro_dse === "1" ? 89 : 99.9;

	return makro_dfe === "1" ? { mensalidadeDfe: valor, ativacaoDfe: 200 } : { mensalidadeDfe: 0, ativacaoDfe: 0 };
}

function calcularValorDse({ makro_dse, makro_cnd, makro_dfe }) {
	const valor = makro_dfe === "1" || makro_cnd === "1" ? 89 : 99.9;

	return makro_dse === "1" ? { mensalidadeDse: valor, ativacaoDse: 200 } : { mensalidadeDse: 0, ativacaoDse: 0 };
}

function calcularCortesiaHorasCapacitacao({ plano_escolhido, capacitacao_adicional, plano_implantacao, quantidade_licencas }) {
	let result;

	if (plano_escolhido === 'light') {
		result = 4;
	}

	if (plano_escolhido === 'basico') {
		result = 4;
	}

	if (plano_escolhido === 'intermediario') {
		const licencas = parseInt(quantidade_licencas);

		if (licencas === 1 || plano_implantacao === 'isento') {
			result = 5;
		} else if (licencas > 1) {
			result = 5;
		}
	}

	if (plano_escolhido === 'avancado') {
		const licencas = parseInt(quantidade_licencas);

		let horas = 6;
		let usuarios = licencas % 2 === 0 ? 2 : 1;

		while (usuarios < licencas) {
			horas++;
			usuarios = usuarios + 2;
		}

		result = horas;
	}

	if (plano_implantacao === 'isento' && parseInt(capacitacao_adicional)) {
		result += parseInt(capacitacao_adicional);
	}

	return result;
}

function calcularCndDseDfe({ makro_cnd, makro_dfe, makro_dse, plano_implantacao }) {
	let { mensalidadeCnd, ativacaoCnd } = calcularValorCnd({ makro_cnd, makro_dfe, makro_dse });
	let { mensalidadeDfe, ativacaoDfe } = calcularValorDfe({ makro_cnd, makro_dfe, makro_dse });
	let { mensalidadeDse, ativacaoDse } = calcularValorDse({ makro_cnd, makro_dfe, makro_dse });

	if (mensalidadeDfe !== 0 && mensalidadeDse !== 0) {
		ativacaoDse = 100;
		ativacaoDfe = 100;
	}

	if (mensalidadeCnd !== 0 && mensalidadeDfe !== 0 && mensalidadeDse !== 0) {
		ativacaoDfe = 100;
		ativacaoDse = 100;
		ativacaoCnd = 100;
	}

	if (plano_implantacao === 'isento') {
		ativacaoCnd = 0;
		ativacaoDse = 0;
		ativacaoDfe = 0;
	}

	return {
		mensalidadeCnd,
		ativacaoCnd,
		mensalidadeDfe,
		ativacaoDfe,
		mensalidadeDse,
		ativacaoDse,
	};
}

function calcularQuantidadeEmpresas({ plano_escolhido }) {
	return tabelaQuantidadeEmpresasPlano[plano_escolhido];
}

function calcularQuantidadeFuncionarios({ plano_escolhido }) {
	return tabelaQuantidadeFuncionariosPlano[plano_escolhido];
}

function calcularCapacitacaoAdicional({ plano_implantacao, capacitacao_adicional }) {
	if (plano_implantacao !== 'isento') {
		return 0;
	}

	return parseInt(capacitacao_adicional) * 100;
}

function calcularValorHospedagem({ plano_implantacao, plano_escolhido, quantidade_licencas }) {
	const licencas = parseInt(quantidade_licencas);

	if (plano_implantacao === 'isento') {
		return 0;
	}

	if (licencas === 1) {
		if (['light', 'basico', 'intermediario'].includes(plano_escolhido)) {
			return 60;
		}

		if (plano_escolhido === 'avancado') {
			return 90;
		}
	} else if (licencas > 1) {
		if (['light', 'basico', 'intermediario'].includes(plano_escolhido)) {
			return 60 + ((licencas - 1) * 30);
		}

		if (plano_escolhido === 'avancado') {
			return 90 + ((licencas - 1) * 45);
		}
	}
}

export function calcular(values) {
	const {
		mensalidadeCnd,
		ativacaoCnd,
		mensalidadeDfe,
		ativacaoDfe,
		mensalidadeDse,
		ativacaoDse,
	} = calcularCndDseDfe(values);
	const horasCortesia = calcularCortesiaHorasCapacitacao(values);
	let valorPlano = calcularValorPlano(values);
	let valorLicenca = calcularValorLicenca(values);
	const valorCapacitacaoAdicional = calcularCapacitacaoAdicional(values);
	const valorMigracao = calcularValorMigracaoPorUsuario(values);
	const valorImplantacao = calcularValorImplantacao(values);
	const descontoImplantacao = calcularDescontoImplantacao(values);
	const valorHospedagem = calcularValorHospedagem(values);

	let mensalidadeTotal = parseFloat(valorPlano)
		+ parseFloat(valorLicenca)
		+ parseFloat(valorHospedagem)
		+ parseFloat(mensalidadeCnd)
		+ parseFloat(mensalidadeDfe)
		+ parseFloat(mensalidadeDse)
		+ parseFloat(values.valor_mensalidade ? values.valor_mensalidade.trim().replace(',', '.') : 0);

	let investimentoInicial = parseFloat(valorPlano)
		+ parseFloat(valorLicenca)
		+ parseFloat(valorMigracao)
		+ parseFloat(valorImplantacao)
		+ parseFloat(valorHospedagem)
		+ parseFloat(mensalidadeCnd)
		+ parseFloat(mensalidadeDfe)
		+ parseFloat(mensalidadeDse)
		+ parseFloat(values.makro_whats_ativacao);

	if (values.plano_escolhido === 'master' && descontoImplantacao) {
		investimentoInicial -= descontoImplantacao;
	}

	// Se há migração de dados usa o valor da migração ao invés do somatório de produtos
	// e soma a parte o Makro Whats. Válido somente para
	if (valorMigracao && values.plano_implantacao && values.plano_implantacao === 'isento') {
		investimentoInicial = valorMigracao + parseFloat(values.makro_whats_ativacao) + parseFloat(valorCapacitacaoAdicional);
	}

	return {
		...values,
		calculo: {
			qtdEmpresas: calcularQuantidadeEmpresas(values),
			qtdFuncionarios: calcularQuantidadeFuncionarios(values),
			horasCortesia,
			investimentoInicial,
			mensalidadeTotal,
			valorPlano,
			valorImplantacao,
			valorLicenca,
			valorMigracao,
			descontoImplantacao,
			mensalidadeCnd,
			ativacaoCnd,
			mensalidadeDfe,
			ativacaoDfe,
			mensalidadeDse,
			ativacaoDse,
			makroWhatsAtivacao: values.makro_whats_ativacao,
			valorCapacitacaoAdicional,
			valorHospedagem,
		},
	};
}

